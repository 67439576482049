import React from "react";
import "../Styles/Home.css";
import frothtestops1 from "../Assets/testops2.png";
import img1 from "../Assets/Icon (3).png";
import img2 from "../Assets/Icon (4).png";
import img3 from "../Assets/Icon (5).png";
import icon1 from "../Assets/testops-icon1.png";
import icon2 from "../Assets/testops-icon2.png";
import icon3 from "../Assets/testops-icon3.png";
import home4 from "../Assets/crm4.png";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();

  const handleContact = () => {
    window.open("/ContactUs", "_blank");
    navigate("/");
  };

  return (
    <div className="container-fluid">
      <div className="Home-Content">
        <div className="row d-flex align-items-center">
          <div className="form-container col-md-6">
            <div className="Main-Content">
              <h1>
                <span className="spanBlack">
                Elevate Test <br /> Management with <br />
                </span>
                <span className="spanRed"> a DevOps-Centric</span><br/>
                <span className="spanRed">Automation</span><span className="spanBlack"> Approach</span>
              </h1>
            </div>
            <div className="Sub-Content">
              <p>
              Effortlessly Coordinate and Monitor Manual and Automated Testing: Effortlessly import results from various sources, seamlessly integrate with any CI platform, efficiently track tests, and swiftly report any issues or failures.
              </p>
            </div>
            <button
              id="contact"
              className="btn btn-danger"
              onClick={handleContact}
            >
              Contact Us
            </button>
          </div>
          <div className="form-container col-md-6">
            <img
              className="img-fluid"
              id="frothcrm-img"
              src={frothtestops1}
              alt="FrothCRM Page 1"
            />
          </div>
        </div>
      </div>
      <div className="Home-Content2">
        <div id="Container">
          <div className="d-flex align-items-center justify-content-center">
            <span style={{marginTop:'1rem',width:'9rem',border:'1px solid #ff0000'}}></span>
          </div>
          <h3 className="text-center mb-4">
          With{" "}
            <span className="span-Red">
            TestOps,
            </span>{" "}
            testing teams can effortlessly
          </h3>
          <div className="row" style={{ marginTop: "1rem" }}>
            <div className="col-md-4" id="card-Content">
              <img src={img1} alt="Feature 1" className="mb-3 card-imgs" />
              <strong className="mb-3">
              Collaborative Management<br />
              </strong>
              <p>
              Task allocation and assessment can be a cumbersome responsibility for project managers.TestOps provides a user-friendly, customer-centric, feedback-enabled approach to managing testing processes, making the process more efficient and cooperative.
              </p>
            </div>
            <div className="col-md-4" id="card-Content">
              <img src={img2} alt="Feature 2" className="mb-3 card-imgs" />
              <strong className="mb-3">
              Seamless Data Export
              </strong>
              <p>
              Exporting data and results to your preferred format can be a complex task with many test case management tools. TestOps simplifies this process, allowing you to swiftly and efficiently export your testing data to Excel, Word, CSV, and more.
              </p>
            </div>
            <div className="col-md-4" id="card-Content">
              <img src={img3} alt="Feature 3" className="mb-3 card-imgs" />
              <strong className="mb-3">
              Comprehensive Requirement Management
              </strong>
              <p>
              TestOps empowers users to define and manage testing requirements, ensuring alignment with project objectives.
              </p>
            </div>
          </div>
          <div className="row" style={{ marginTop: "0rem" }}>
            <div className="col-md-4" id="card-Content">
              <img src={img1} alt="Feature 1" className="mb-3 card-imgs" />
              <strong className="mb-3">
              Detailed Test Scenarios
              </strong>
              <p>With TestOps, you can create comprehensive test scenarios that outline the necessary steps to validate specific application functionality or features.
              </p>
            </div>
            <div className="col-md-4" id="card-Content">
              <img src={img2} alt="Feature 2" className="mb-3 card-imgs" />
              <strong className="mb-3">Automation with BDD Framework
              </strong>
              <p>
              Seize Every Opportunity Make the most of your comprehensive customer profiles to convert leads and optimize your sales strategies. Our CRM equips you with the tools needed to capture vital leads, manage opportunities, and generate personalized quotes, freeing up valuable time for selling.
              </p>
            </div>
            <div className="col-md-4" id="card-Content">
              <img src={img3} alt="Feature 3" className="mb-3 card-imgs" />
              <strong className="mb-3">Manual and Rest APIs Test Case Execution
              </strong>
              <p>
              TestOps provides the flexibility to execute test cases both manually and via Rest APIs, allowing users to perform tests by hand or automate them using Rest APIs.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="Home-Content3">
        <div className="row d-flex align-items-center">
          <div className="form-container col-md-6">
            <div className="Main-Content">
              <h3>
                <span className="spanBlack">
                Monitor and
                </span>{" "}
                <span className="spanRed">Report on Progress</span>
              </h3>
            </div>
            <div className="Sub-Content" style={{ marginTop: "0.4rem" }}>
              <p>
              We recognize the critical role test case management plays in the success of any software project. That’s why we’ve engineered TestOps to be highly flexible and customizable, allowing teams to adapt it to their unique requirements
              </p>
              <ul>
                <li>Analyse test case execution results</li>
                <li>Identify and address issues or defects promptly</li>
                <li>Simplify the process of creating test cases</li>
                <li>Ensure on-time, on-budget, high-quality project deliveries</li>
                <li>Customizable Test Case Management System:</li>
              </ul>
            </div>
          </div>
          <div className="form-container col-md-6">
            <img
              className="img-fluid"
              id="frothcrm-img"
              src={home4}
              alt="FrothCRM Page 1"
            />
          </div>
        </div>
      </div>
      <div className="Home-Content2" style={{ marginTop: "2rem"}}>
        <div id="Container">
          {/* <div className="d-flex align-items-center justify-content-center">
            <span style={{marginTop:'1rem',width:'9rem',border:'1px solid #ff0000'}}></span>
          </div> */}
          <h2 className="text-center mb-4">
          When we say{" "}
            <span className="span-Red">
            TestOps
            </span>{" "}
            makes testing simple, we’re not kidding
          </h2>
          <h6 className="text-center">Design and save templates for test cases, streamlining your workflow and saving time.</h6>
          <div className="row" style={{ marginTop: "3rem",marginBottom:'3rem' }}>
            <div className="col-md-4" id="card-Content2">
              <img src={icon1} alt="Feature 1" className="mb-2 card-imgs" />
              <h5 id="title-2" className="mb-3">
              Detailed Test Scenarios<br />
              </h5>
              <p>
              With TestOps, you can create comprehensive test scenarios that outline the necessary steps to validate specific application functionality or features.
              </p>
            </div>
            <div className="col-md-4" id="card-Content2">
              <img src={icon2} alt="Feature 2" className="mb-2 card-imgs" />
              <h5 id="title-2" className="mb-3">
              Custom Test Reports
              </h5>
              <p>
              Create personalized test reports with TestOps, ensuring that your test coverage, references, and statuses are always up-to-date.
              </p>
            </div>
            <div className="col-md-4" id="card-Content2">
              <img src={icon3} alt="Feature 3" className="mb-2 card-imgs" />
              <h5 id="title-2" className="mb-3">
              Seamless Attachments
              </h5>
              <p>
              TestOps simplifies the attachment process for various fields, enabling you to include essential information effortlessly.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
