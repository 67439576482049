import React, { useState, useEffect } from "react";
import "../Styles/Header.css";
import { useNavigate, useLocation } from "react-router-dom";
import FrothDeskLogo from "../Assets/Froth_Test_OPS-1-1.png";
import { Navbar, Nav } from "react-bootstrap";

const FrothHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activePage, setActivePage] = useState("/ContactUs");

  // const getToken = async () => {
  //   try {
  //     const response = await fetch("https://frothcrm.com/crm_login.php", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/x-www-form-urlencoded",
  //       },
  //     });
  //     if (response.ok) {
  //       const data = await response.json();
  //       localStorage.setItem('accessToken', data.access_token);
  //     } else {
  //       alert("Failed to login. Please try again.");
  //     }
  //   } catch (error) {
  //     console.error("Error during login:", error);
  //     alert("Failed to login. Please try again.");
  //   }
  // };
  // useEffect(()=>{
  //   getToken()
  // })

  useEffect(() => {
    // Update the active page based on the current location
    setActivePage(location.pathname);
  }, [location.pathname]);

  const handleNavigation = (path) => {
    setActivePage(path);
    navigate(path);
  };

  const handleExternalLink = (url) => {
    window.open(url, "_blank");
  };

  const handleLog = () => {
    window.open('/ChangeLog', '_blank');
  };

  const hanldeSelfService = () => {
    window.open('/Login', '_blank');
  };

  return (
    <Navbar bg="white" expand="lg" fixed="top">
      <div className="container">
        <div
          className="container-fluid"
          style={{ fontWeight: "500", fontSize: "14px" }}
        >
          <Navbar.Brand onClick={() => handleNavigation("/")}>
            <img
              src={FrothDeskLogo}
              alt="FrothTestops Logo"
              style={{ height: "5rem" }}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link
                onClick={() => handleNavigation("/")}
                className={activePage === "/" ? "active" : ""}
              >
                Home
              </Nav.Link>
              <Nav.Link
                onClick={() =>
                  handleExternalLink("https://roboticodigital.com/")
                }
              >
                Robotico Digital
              </Nav.Link>
              <Nav.Link onClick={hanldeSelfService}
              className={activePage === "/SelfService" ? "active" : ""}>
              Self Service
            </Nav.Link>
            {/* <Nav.Link onClick={hanldePricing} style={{marginRight:'1em'}}
            className={activePage === "/Pricing" ? "active" : ""}
            >
              Pricing
            </Nav.Link> */}
            <Nav.Link onClick={handleLog}
            className={activePage === "/ChangeLog" ? "active" : ""}
            >
              Change Log
            </Nav.Link>
              <Nav.Link
                className={`${activePage === "/ContactUs" ? "active" : ""}`}
                onClick={() =>
                  handleExternalLink("https://froth.tech/contact-us/")
                }
              >
                Contact Us
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </div>
      </div>
    </Navbar>
  );
};

export default FrothHeader;
