// Footer.js
import React from 'react';
import '../Styles/RightsFooter.css'; // Import the CSS file for styling

const RightsFooter = () => {
  return (
    <footer className="footer">
      <p style={{marginLeft:'3rem',marginBottom:'0'}}>©{new Date().getFullYear()} FrothTestOps. All Rights Reserved.</p>
    </footer>
  );
};

export default RightsFooter;
