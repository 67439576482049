import React, { useState,useEffect } from "react";
import "../../Styles/SelfService.css";
import { apiUrl } from "../../Pages/Config";
import { toast } from "react-toastify";
import { useNavigate,useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserData, setTickets } from "../../Pages/Store";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const path = location.pathname.toLowerCase();
    if (path === "/login") {
      localStorage.removeItem('Login')
    } 
  }, []);

  const accessToken = localStorage.getItem('accessToken');

  const validateForm = () => {
    const errors = {};
    if (!username) errors.name = "Username required";
    if (!password) errors.password = "Password required";
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleNavigate = (ticketData) => {
    navigate('/Self-Service', { state: { tickets: ticketData } });
  }

  
  const getUserDetails = async (event) => {
    event.preventDefault(); // Prevent the default form submission
    if (!validateForm()) return;
  
    try {
      const response = await fetch(apiUrl + "customerLogin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          customer_name: username,
          customer_password: password,
        }),
      });
  
      const UserData = await response.json();
      if (response.status === 200 && UserData.status === "Active") {
        toast.success("Login successful", {
          position: "top-right",
          autoClose: 3000,
        });
        localStorage.setItem("Id", UserData?.id);
        localStorage.setItem("Login", 'true');
        localStorage.setItem("email", UserData?.email);
        localStorage.setItem("name", UserData?.customer_name);
        dispatch(setUserData(UserData));

        const ticketResponse = await fetch(
          `${apiUrl}fetchUserTickets/${UserData?.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        // Inside the try block after dispatching the user data
        const fetchResponse = await ticketResponse.json();
        if (fetchResponse.message && fetchResponse.message.includes("Account tickets not found")) {
          dispatch(setTickets([]));
          handleNavigate([]);
        } else{
          const data = fetchResponse.filter(
            (project) =>
              project.product_name &&
              project.product_name.toLowerCase() === "frothtestops"
          );
          dispatch(setTickets(data));
          handleNavigate(data);
        }
       
      } else if (response.status === 200 && UserData.status === "Inactive") {
        toast.error(
          "Your account is inactive, please contact your supervisor",
          {
            position: "top-right",
            autoClose: 3000,
          }
        );
        setUsername("");
        setPassword("");
      } else {
        toast.warn(UserData?.message, {
          position: "top-right",
          autoClose: 3000,
        });
        setUsername("");
        setPassword("");
      }
    } catch (error) {
      toast.error(error?.message, {
        position: "top-right",
        autoClose: 3000,
      });
      setUsername("");
      setPassword("");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      getUserDetails(event);
    }
  };

  return (
    <div className="container" id="login-container">
      <div className="row justify-content-center">
        <div className="col-md-4">
          <div className="card" style={{ padding: "0" }}>
            <div
              className="card-header text-center"
              style={{ padding: "1rem" }}
            >
              <strong>Login</strong>
            </div>
            <div className="card-body text-left">
              <form onSubmit={getUserDetails}>
                <div className="form-group">
                  <label htmlFor="username">Username</label>
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                  {formErrors.name && (
                    <span style={{ color: "#FF0000" }}>{formErrors.name}</span>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                  {formErrors.password && (
                    <span style={{ color: "#FF0000" }}>
                      {formErrors.password}
                    </span>
                  )}
                </div>

                <div className="form-group text-center">
                  <button type="submit" className="btn btn-dark">
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
